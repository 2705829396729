footer.page-footer {
  background-color: $grey;
  color: #212529;
  font-size: $font-size-base * 1.125;
  line-height: 23px;
  margin-top: 30px;
  position: relative;

  @extend .pt-4;
  @extend .pb-4;

  body.sticky-footer & {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  body:not(.sticky-footer) &.fixed-footer {
    @include media-breakpoint-up(sm) {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  .footer-columns {
    li {
      display: inline-block;
      border-bottom: 1px solid black;
      padding-bottom: 5px;

      a {
        font-size: $font-size-base !important;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .footer-menu-right {
    li {
      padding: 0 0.5rem;

      &:last-child {
        padding-right: 0;
      }

      @include media-breakpoint-up(sm) {
        display: inline-block;

        + li {
          border-left: 1px solid #000;

          @include media-breakpoint-only(sm){
            &:last-child {
              border-left: 0;
            }
          }
        }
      }
    }
  }

  div {
    .border-right {
      border-color: black !important;
    }

    a {
      font-family: $font-poppins;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .box-chevron-up {
    height: 50px;
    padding: 1.1563rem !important;
    position: absolute;
    right: 16px;
    top: -47px;
  }
}

.row-padding {
    min-height: 50px;

}
.footer-column{
  h5{
    line-height: 23px;
  }
  a{
    color: #3A3A3A;
    font-weight: 300;
    line-height: 18px;
  }
}

.row-footer-login{
  padding-bottom: 128px;
}
