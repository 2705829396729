$icomoon-font-family: "avia-icons" !default;
$icomoon-font-path: "/fonts/avia-icons" !default;

$icon-login: "\e915";
$icon-lock: "\e913";
$icon-user: "\e914";
$icon-id: "\e912";
$icon-chevron-up: "\e911";
$icon-close: "\e90c";
$icon-sportsicon: "\e90d";
$icon-gas-station: "\e90e";
$icon-credit-card-blocked: "\e90f";
$icon-dropliguidicon: "\e910";
$icon-card: "\e901";
$icon-discount: "\e902";
$icon-phone: "\e903";
$icon-location: "\e904";
$icon-chevron-down: "\e905";
$icon-check-solid: "\e906";
$icon-check: "\e907";
$icon-star-regular: "\e908";
$icon-search: "\e909";
$icon-star-solid: "\e90a";
$icon-facebook: "\e90b";
$icon-out: "\e900";



