.logo{
   width: 120px;
 }

.title-image-container {
  background-image:  url("/assets/images/track.png") ;
  background-size: cover;
  height: 580px;

 // &::before, &::after {
 //   content: '';
 //   display: table;
 //   height: 580px;
 //   width: calc((100vw - 1300px) / 2);
 // }
//
 // &::before {
//
 // }
//
 // &::after {
//
 // }

  .title-image{
    position: absolute;
    top: 205px;
    color: white;
  }




}