// Colors

$red: #E10000;
$dark-red: #D30E0E;
$red-2: #DB0000;
$grey: #E1E1E1;
$dark-gray: #787878;
$grey2: #E6E6E9;

$light-gray: #838383;
//////

$body-color: $dark-gray;

// Default for buttons
$primary: $red;
//$secondary:
//$success:
//$info:
//$warning:
//$danger:
//$light:
//$dark:

// Checkbox
$custom-control-indicator-checked-color: #e10000;
$custom-control-indicator-checked-border-color: #e10000;
$custom-control-indicator-checked-bg: #ffffff;
$custom-control-indicator-checked-box-shadow: #e10000;

$container-max-widths: (
        md: 820px,
        lg: 1160px,
        xl: 1344px
) !default;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1400px
) !default;

// Font family
$font-helvetica: "Helvetica Neue", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arial, "Noto Sans", sans-serif;
$font-poppins: 'Poppins', sans-serif;
$font-family-sans-serif: $font-poppins !default;

//Forms
$input-btn-focus-width: 0;
// Font sizes
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$html-font-size: 16px;
$body-font-size: 17px;
$rfs-rem-value: 16;
$line-height-base: 1.25 !default;

$h1-font-size: 40px;
$h2-font-size: 32px;
$h3-font-size: 28px;
$h4-font-size: 24px;
$h5-font-size: 20px;
$h6-font-size: 18px;

//Forms
$input-btn-focus-width: 0;

// Bootstrap alerts
$alert-border-radius: 0;
