
.header-img-section {
  position: relative;

  .header-image-box {
    background-image: url("/assets/images/avia_heiz-640x193.jpg");
    overflow: hidden;
    position: relative;

    .blur-cover {
      display: block;
      position: absolute;
      top: 0;
      left: -15px;
      right: -15px;
      height: 100%;
      background-size: cover;
      background-position: 50% 50%;
      filter: blur(7px);

      background-image: url("/assets/images/avia_heiz-640x193.jpg");
    }

    .header-img-col {
      .centered-image-wrap {
        .centered-image {
          background-image: url("/assets/images/avia_heiz-1920x580.jpg");
          background-size: cover;
          background-position: 50% 50%;
          box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
          height: 300px;
          position: relative;

          @include media-breakpoint-up(lg) {
            height: 480px;
          }

          @include media-breakpoint-between(sm, lg) {
            height: 380px;
          }

          .header-img-content {
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding-left: 8.333333333%;
            position: relative;
            z-index: 1;

            .header-title {
              display: block;
              color: #fff;
              font-size: 50px;
              margin-bottom: 0;
              position: relative;
              line-height: 1.25;
              font-weight: 700;
              z-index: 2;

              h1 {
                color: inherit;

                @include media-breakpoint-down(md) {
                  font-size: 40px;
                }
                @include media-breakpoint-down(xs) {
                  font-size: 30px;
                }
              }

              h3 {
                color: inherit;

                @include media-breakpoint-down(md) {
                  font-size: 20px;
                }
                @include media-breakpoint-down(xs) {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.row-icons {
  margin-top: -114px;

  @include media-breakpoint-up(lg) {
    margin-top: -113px;
  }

  .col {
    .d-block {
      position: relative;
      text-align: center;

      .d-block-icon {
        padding: 22px 20px;

        i, img, svg {
          display: block;
          width: auto;
          height: 70px;
          margin: 0 auto;
        }

        i {
          font-size: 70px;
        }
      }

      .d-block-title {
        display: block;
        font-size: 18px;
        font-weight: 400;
        padding: 15px 5px;
        @extend .block-dark-red;

        br {
          @include media-breakpoint-up(xl) {
            display: none;
          }
        }
      }
    }
  }
}

.row-image {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1344px;
  clear: both;

  > .columns.large-12 {
    @media only screen and (min-width: 64.0625em) {
      width: 100%;
    }
  }
}

.columns {
  float: left;
  width: 100%;
}