/**
 * Content
 */
.content-page-container {
  h3 {
    color: #E10000;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: 20px;
    padding-bottom: 10px;
  }

  .image-text-item {
    .col-content {
      box-shadow: 0 2px 7px 0 rgba(183, 183, 183, 0.5);
      border: 0 !important;
      text-align: left;
      padding: 48px 20px 40px;

      @include media-breakpoint-up(md) {
        padding-left: 40px;
        padding-right: 40px;
      }

      @include media-breakpoint-up(xl) {
        padding-left: 70px;
        padding-right: 70px;
      }
    }

    .col-img {
      background-position: center center;
      background-size: cover;
      height: 50vw;


      @include media-breakpoint-up(md) {
        height: 410px;
      }

      @include media-breakpoint-up(lg) {
        height: 100%;
      }
    }

    .content {
      margin-bottom: 35px;
    }
  }
}
