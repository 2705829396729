.page-item.active .page-link {

  color: #fff;
  background-color: $red;
  border-color: $red;
  border-radius: 0;
}
.page-item .page-link {
  color: $red;
  border: 0;
  border-radius: 0;
  padding-top: 10px;
  padding-right: 17px;
  padding-bottom: 10px;
  padding-left: 17px;
  font-weight: bold;
  font-size:  $h5-font-size;
  line-height: 23px;
  margin-right: 24px;
  &:hover {
    color: #fff;
    background-color: $dark-red;
  }
}

.pagination {
  padding-bottom: 88px;
}
