.card-deck {
    @include media-breakpoint-only(lg) {
        -webkit-column-count:2;
        -moz-column-count:2;
        column-count:2;
    }

    @include media-breakpoint-only(xl) {
        -webkit-column-count:3;
        -moz-column-count:3;
        column-count:3;
    }
}
.card-reward{

    .card-body{
        line-height: 28px;
        //padding: 16px  31px 31px;
    }

    .text-bottom-left{
        color: white;
        bottom: 23px;
        left: 32px;
        line-height: 23px;
        font-size: 15px;
        text-align: left;

    }
}

.card-image{
  background-position: center center;
  background-size: cover;
  border-radius: 0;
  height: 45vw;

  @include media-breakpoint-up(sm) {
    height: 175px;
  }

  @include media-breakpoint-up(lg) {
    height: 175px;
  }

  @include media-breakpoint-up(xl) {
    height: 215px;
  }
}

.border-up-card {
    width: 100%;
    height: 2px;
    background-color:  #EBEBEB;

    margin-left: 26px;
    margin-right: 26px;
}

.card-reward {
  box-shadow: 0 2px 7px 0 rgba(183,183,183,0.5);
  border: 0 !important;
}
