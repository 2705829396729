
.icon-out , .icon-login {
  font-size: 20px;
  padding-left: 24px;
}

.icon-gas-station{
  font-size: 84px;
}


.icon-close{
 color: $red;
  font-size: 30px;
}
.icon-id{
  font-size:50px;
}
.icon-gift{
  width: 80px;
  height: 80px;
}

.icon-discount-block{
  padding-top: 25px;
  padding-bottom: 27px;
}
.icon-shoppingcart-block{
  padding: 34px;

}

.icon-id-block{
  padding: 35px;

}
.icon-gifticon-block{
  padding: 20px;
}


.icon-brand-block{
  padding: 23px;
}


.icon-discount{
  font-size: 69px;
}

.icon-chevron-up{
  vertical-align: top;
  color: white;
  font-size: 7px;
}

.icon-card{
  font-size: 48.5px;
}


.icon-credit-card-blocked{
  font-size: 60px;

}

.row-icons{
  @include media-breakpoint-up(lg) {
    margin-top: -126px;
  }
  margin-top: -70px;
}


.icon-object-red{
  margin-top: 17px;
  height: 41px;
  width: 40px;
  filter: brightness(0) saturate(100%) invert(12%) sepia(98%) saturate(5323%) hue-rotate(360deg) brightness(82%) contrast(119%);
}