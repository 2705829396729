nav {
  background-color: white;

  .header-btn {
    opacity: 1;

    @media only screen and (max-width: 640px) {
      display: none;
    }
  }
}

.nav-period {
  .btn-link {
    border-bottom: 2px solid $primary;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 7px;
    position: relative;
    text-decoration: none !important;
    transition: all 0.3s ease-in-out;
    vertical-align: sub;

    &.white {
      color: #fff;
      border-bottom-color: #fff;
    }
  }
  .iconify {
    color: #3A3A3A;
  }
  .arrows-left-right {
    max-width: 100px;
  }
}

.navbar-toggler {
  border: 0;
  line-height: 5px;
  height: 50px;
  background: transparent !important;
}

.navbar-toggler .top-bar {

  transform: rotate(28deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .icon-bar {
  opacity: 0;
  display: none;
}

.navbar-toggler.collapsed .icon-bar {
  opacity: 1;
  display: block;
}

.navbar-toggler .icon-close {
  display: block;
  opacity: 1;
}

.navbar-toggler.collapsed .icon-close {
  display: none;
  opacity: 0;
}

.icon-bar {

  width: 30px;
  height: 3px;
  background-color: $red;
  display: block;
  transition: all 0.2s;
  margin-top: 6px
}

.line-height {
  line-height: 25px;
}

.break-column-height {
  height: 60px;
  margin-top: -7px;
}

.break-column {
  height: 60px;
  width: 2px;
  background-color: #F8F8F8;
  margin-top: -7px;
}

.navbar-menu {
  border-top: 3px solid $grey;
  font-family: $font-helvetica;

  .big-menu {
    ul {
      li {
        display: block;
        font-size: 28px;
        font-weight: 400;
        height: 52px;
        line-height: 1.9;
        position: relative;
        margin-bottom: 6px;

        &.has-icon-object {
          a {
            border-bottom: 3px solid $red !important;
            margin-right: 60px;
            position: relative;
            @extend .text-danger;

            &:hover {
              text-decoration: none;
            }
          }

          .icon-object {
            filter: brightness(0) saturate(100%) invert(12%) sepia(98%) saturate(5323%) hue-rotate(360deg) brightness(82%) contrast(119%);
            height: 39px;
            margin-top: 10px;
            position: absolute;
            right: -50px;
            width: 39px;
          }
        }
      }
    }
  }

  .small-menu {
    ul {
      li {
        display: block;
        font-size: 18px;
        font-weight: 300;
        line-height: 27px;
        margin-bottom: 15px;
      }
    }
  }
}

.nav-register-container {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 0;
  padding-top: 20px;
}

.nav-padding-bottom {
  padding-top: 5px;
  border-top: 5px solid #E10000;
  min-height: 200px;
}

.border-bottom-nav {
  width: 100%;
  height: 2px;
  background-color: #F8F8F8;
}

.register-brand {
  padding-top: 5px;
}

.padding-10 {
  padding-left: 10px;
  padding-right: 10px;
}
