// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import 'fonts/avia-icons/style.scss';

@import 'source/utils/all';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'source/components/all';

// override bootstrap
.alert-danger {
  background: #FFCCCC;
  color: #C80000;
  border-color: #C80000;
}
