html {
  font-size: $html-font-size;
}

.input-group-text {

  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0px;
}

.input-group-text-required {
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  border-right: 1px solid $grey;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 0px;
  color: $red;
}

.clear-padding {
  padding: 0px;
}

.col-no-padding-right {
  padding-right: 0px;
}

.col-no-padding-left {
  padding-left: 0px;
}

ul.darkpoint {
  list-style-type: disc;
}

ul.circle {
  list-style-type: circle;
}

.m-w-1700 {
  min-width: 1700px;
}

body {
  @include font-size($body-font-size);

  &.sticky-footer {
    min-height: 100vh;
    position: relative;
    margin: 0;
    padding: 0 0 230px;

    @include media-breakpoint-only(sm) {
      padding-bottom: 246px;
    }
  }

  .border-top {
    border-top-width: 4px !important;
  }

}

.nav-container {
  padding-bottom: 32px;
  padding-top: 32px;

  @include media-breakpoint-only(sm) {
    @include make-container();
  }
}

.under-line {
  text-decoration: underline;
}

.font-description {
  color: #000000;
  font-weight: 300;
  line-height: 22px;
}

.font-poppins {
  font-family: $font-poppins !important;
}

.color-dark-gray {
  color: $dark-gray;
}

.color-light-gray {
  color: $light-gray
}

.bg-light-red {
  background-color: rgba(249, 0, 0, 0.44);
}

.font-poppins {
  font-family: $font-poppins !important;
}

.color-dark-gray {
  color: $dark-gray;
}

p {
  line-height: 1.5;

  a {
    color: #E10000;
    font-weight: 300;
    text-decoration: underline;
  }
}

.text-page ul {
  a {
    color: #E10000;
    font-weight: 300;
    text-decoration: underline;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
  line-height: 1.25;
}

h1 {
  font-weight: 700;
}

.top-card-transaction {
  background-color: rgba(249, 0, 0, 0.44);
  text-align: center;
  color: white;
}
