
.total-sort{
  padding-top: 11px;
  padding-bottom: 57px;
}

.col-card{
  padding-left: 20px;
  padding-right: 20px;
  @include media-breakpoint-up(lg) {
  padding-left: 40px;
  padding-right: 0;
  }
}

.form-search{
  color: $dark-gray;
  font-size: 18px;
  line-height: 27px;
}