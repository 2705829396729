@import "variables";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?swbloo');
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?swbloo#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?swbloo') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?swbloo') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?swbloo##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-login {
    &:before {
        content: $icon-login;
    }
}
.icon-lock {
    &:before {
        content: $icon-lock;
    }
}
.icon-user {
    &:before {
        content: $icon-user;
    }
}
.icon-id {
    &:before {
        content: $icon-id;
    }
}
.icon-chevron-up {
    &:before {
        content: $icon-chevron-up;
    }
}
.icon-close {
    &:before {
        content: $icon-close;
    }
}
.icon-sportsicon {
    &:before {
        content: $icon-sportsicon;
    }
}
.icon-gas-station {
    &:before {
        content: $icon-gas-station;
    }
}
.icon-credit-card-blocked {
    &:before {
        content: $icon-credit-card-blocked;
    }
}
.icon-dropliguidicon {
    &:before {
        content: $icon-dropliguidicon;
    }
}
.icon-card {
    &:before {
        content: $icon-card;
    }
}
.icon-discount {
    &:before {
        content: $icon-discount;
    }
}
.icon-phone {
    &:before {
        content: $icon-phone;
    }
}
.icon-location {
    &:before {
        content: $icon-location;
    }
}
.icon-chevron-down {
    &:before {
        content: $icon-chevron-down;
    }
}
.icon-check-solid {
    &:before {
        content: $icon-check-solid;
    }
}
.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-star-regular {
    &:before {
        content: $icon-star-regular;
    }
}
.icon-search {
    &:before {
        content: $icon-search;
    }
}
.icon-star-solid {
    &:before {
        content: $icon-star-solid;
    }
}
.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}
.icon-out {
    &:before {
        content: $icon-out;
    }
}

