
@include media-breakpoint-down(lg) {
    .e-error {
        display: block;
        float: none !important;
    }
}

.error-field {
    label {
        color: #e10000;
    }
    .form-style {
        border-color: #E10000;
    }

    .e-error {
        float: right ;
    }
}
