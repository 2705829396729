.account-sub-header {
    color: #787878;
    font-size: 30px;
    font-weight: 600;
    line-height: 46px;
    padding-left: 0px;
    padding-bottom: 15px;
}

.account-form-design {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 1px;
}

.account-sub-header-row {
    padding-right: 14px;
}
