.block-red {
	color: white;
	background-color: $red;

	&:hover {
		color: white;
		text-decoration: none;
	}

  * {
    color: inherit;
  }
}

.block-medium {
	display: inline-block;
	font-size: 19px;
	padding: 23px 34px !important;

	@include media-breakpoint-up(sm) {
		padding: 23px 60px !important;
		font-size: 28px;
	}

	@include media-breakpoint-up(lg) {
		font-size: 24px;
	}
}

.block-small {
	@include media-breakpoint-up(xl) {
		width: 194px;
	}

}

.pl-red-box {
	@include media-breakpoint-up(xl) {
		padding-left: 2.8rem !important;
	}
}

.block-dark-red {
	color: white;
	background-color: $dark-red;
}

.block-padding-less {
	@include media-breakpoint-up(xl) {
		padding-bottom: 22px !important;
	}
}

.block-gray {
	color: $black;
	background-color: $grey;

	&:hover {
		color: $black;
		text-decoration: none;
	}

  * {
    color: inherit;
  }
}

.block-cards {
  .block-card {
    padding: 30px;

    @include media-breakpoint-only(lg) {
      padding: 7%;
    }

    @include media-breakpoint-up(xl) {
	padding: 34px 73px;
}
  }

  > * {
    @include media-breakpoint-up(sm) {
      @media (max-width: 720px) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
