.hidden {
    display: none;
}

.form-style {
    height: 48px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    margin-top: -1px;

}
select.form-style {
    outline: 1px solid #ced4da;
    border-color: transparent;
}

.form-button-secondary {
}


.form-button-primary {
    background-color: #E10000;
    color: #ffffff;
    border-color: #E10000;
    margin-top: 0px;
    border-radius: 0px;
    padding-top: 8px;
    padding-left: 9px;
  height: 50px;
    &:hover {
        background-color: $dark-red;
    }
}

.form-sub-label {
    color: $dark-gray;
    font-size: 18px;
    line-height: 27px;
    margin-left: -1px;
    margin-top: -1px;
}

.form-header {
    padding-top: 6px;
    font-size: 30px;
    font-weight: 600;
    color: #E10000;
    line-height: 46px;
    margin-left: -2px;
}

.login-form-block-container > .row > .col {
    margin-bottom: 3px;
}

.form-box-2 {
    width: 100%;
    max-width: 524px;
    margin: auto;
    padding-top: 35px;
    padding-right: 2px;
    padding-left: 2px;
}

.return-red-url {

    margin-left: -22px;
    padding-top: 10px;
}

.form-pw-2 {
    margin-bottom: -.1rem;
}

.form-box-3 {
    width: 100%;
    max-width: 524px;
    margin: auto;
    padding-right: 2px;
    padding-left: 2px;
}

.form-input {
    height: 50px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

.form-select {
  border: 0;
  border-bottom: 2px solid $red;
  color: $red-2;
}

.form-control:focus{
    border-color: $dark-red;
}

.cross {
    cursor: pointer;
}
.img-height{
  background-size: cover;
  background-repeat: no-repeat ;
  background-position: center;
  @include media-breakpoint-up(lg) {

    min-height: 100%;
  }
  @include media-breakpoint-up(md) {

    height: 400px;
    margin-bottom: 10px;
  }
  @include media-breakpoint-down(md) {
    background-position-y: top;
    height: 350px;
    margin-bottom: 10px;
  }

}