//
// Login Form Page
//


body {
    background-color: #ffffff !important;
}

.container-login {
    text-align: center;
    min-height: 100%;
}

.login-header-logo .logo {
    min-width: 252px;

    padding-top: 80px;
}


.container-login {

    .login-form-header {
        padding-top: 7px;
        font-size: 30px;
        font-weight: 600;
        color: #E10000;
        line-height: 46px;
        padding-left: 4px;
    }

    .login-form-sub-header {
        margin-top: -7px;
        margin-left: -5px;
        font-size: 16px;
        color: $dark-gray;
        font-weight: 300;
        line-height: 22px;
        font-family: $font-poppins-light;
        padding-left: 4px;
    }

    .login-form-header-inverse {
        font-size: 30px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 46px;
        padding-bottom: 10px;
        padding-right: 10px;
    }
    .login-form-sub-header-inverse {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        width: 378px;
        margin: auto;
    }

    .login-form-block {
        padding-left: 20px;
        padding-bottom: 1px;
    }

    .login-form-side-block {
        background-color: #E10000;
        margin-left: 6px;
        height: 100%;
        margin-right: 4px;
    }

    .login-form-block-container {
        padding: 20px;
        background-color: #FFFFFF;
        padding-top: 50px;
        padding-bottom: 50px;
        border-radius: 0px 10px 10px 10px;
        margin-right: 3px;
    }

    .new-account-block {
        padding-top: 85px;
        padding-left: 12px;
    }

    .login-form-button {
        background-color: #E10000;
        color: #ffffff;
        border-color: #E10000;
        margin-top: 4px;
        margin-left: 1px;
        margin-right: -1px;
        border-radius: 0px;
        padding-top: 8px;
        padding-left: 9px;
    }

    .login-form-button:hover {
        background-color: #d40000;
    }

    .login-form-button:focus {
        box-shadow:0 0 0 .2rem rgba(225,0,0,.5);
        background-color: #d40000;
        border-color: #E10000;
        color: #000000;
    }

    .login-form-button-style {
        margin-bottom: 1rem;
    }

    .register-block {
        min-height: 60px;
        padding-top: 100px;
    }

    .btn-register {
        width: 217px;
        margin: auto;
        border-radius: 0px;
        height: 52px;
        vertical-align: middle;
        padding-top: 12px;
        background: #FFFFFF;
        color: #E10000;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        border-color: unset;
    }

    .btn-register:hover {
        background: #FFEFFF;
        color: #d40000;
    }

}


@include media-breakpoint-down(lg) {
    .container-login {
        .login-form-side-block {
            margin-top: 30px;
            margin-left: 5px;
            margin-right: 0px;
        }
    }

    .footer-mobile-style {
        text-align: center;
    }

    footer div .border-right {
        border-right: unset !important;
    }

    footer .d-flex {
        display: block !important;
    }


    .sm-100 {
        width: 100%;
    }
}


.main-login {
    min-height: 544px;
    padding-bottom: 170px;
}

.login-form-block-container {
    min-height: 100%;
    //background-color: #333333;
    box-shadow: 0 2px 24px 6px rgba(0,0,0,0.025);
}

.color-red {
    color: #E10000;
}

.form-box-header > h1 {
    font-size: 30px;
    font-weight: 600;
    color: #E10000;
    line-height: 46px;
}


.form-box {
    height: 248px;
    width: 100%;
    max-width: 524px;
    margin: auto;
    padding-top: 34px;
    padding-right: 2px;
    padding-left: 1px;

}

.form-label {
    color: $dark-gray;
    font-size: 18px;
    line-height: 27px;
}

.form-pw {
    margin-top: -3px;
}

.form-pw-in {
    margin-top: -5px;
    font-size: 19px;
    letter-spacing: 1px;
}

.red-url {
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    padding-left: 20px;

}

.url-pad {
    padding-top: 55px;
}

.footer-bottom-line {
    width: 100%;
    height: 5px;
    background-color: #E10000;
    display: block;
    bottom: 5px;
    position: absolute;
}

.login-side-block {
    padding-bottom: 1px;

}


.form-icon-clean .input-group-text {
    background-color: #FFF;
}


//
// Icons
//

input{
    border:none;
    background-color: transparent;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.icon-flow {
    color: gray;
    float: right;
    position: absolute;
    right: 22px;
    top: 14px;
    font-size: 15px;
}

.icon-flow-username {
    color: gray;
    float: right;
    position: absolute;
    right: 20px;
    top: 17px;
    font-size: 15px;
}

@include media-breakpoint-down(md) {
    .login-form-sub-header-inverse {
        width: 80% !important;
        margin: auto !important;
        text-align: center !important;
    }
}


@media (max-width: 350px) {
    .register-block {
        padding-top: 0px !important;
    }
}

.btn-height {
    height: 50px;
}

@include media-breakpoint-down(lg) {
    .form-box {
        height: 100%;
    }
}

.padding-top-25 {
    padding-top: 25px;
}

@include media-breakpoint-up(sm) {
    .login-form-block {
        height: 544px;
    }

    .login-side-block {
        height: 544px;
    }
}