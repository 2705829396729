.container {
    text-align: center;
    .form-page {
        width: 100%;
        background-color: white;
        box-shadow: 0 2px 24px 6px rgba(0,0,0,0.025);
    }
}

.form-page {
    padding-top: 66px;
}

.form-page-padding {
    padding-left: 112px;
    padding-right: 112px;
    padding-top: 66px;
    padding-bottom: 37px;
}

.form-style {
    height: 50px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    margin-top: -1px;
}

.form-header {
    padding-top: 7px;
    font-size: 30px;
    font-weight: 600;
    color: #E10000;
    line-height: 46px;
    padding-left: 4px;
}

.input-group > span {
    height: 50px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    margin-top: -1px;
}

.required {
    color: #D20000;
}

.address-header {
    padding-top: 20px;
}

.register-header {
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    padding-top: 15px;
    padding-bottom: 37px;
}

.register-sub-header {
    color: $dark-gray;
    font-size: 30px;
    font-weight: 600;
    line-height: 46px;
    padding-left: 5px;
    padding-bottom: 15px;
}

.register-sub-header-right {
    padding-top: 16px;
    padding-right: 5px;
}

.register-sub-header-right > h4 {
    color: #6B6B6B;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
}

.register-sub-header-row {
    padding-right: 14px;
}

.register-label {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: $dark-gray;
}

.form-design {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
}

.register-form-group {
    margin-top: 12px;
}

.radio-label {
    font-size: 16px !important;
    font-weight: 500;

    line-height: 25px !important;
}

.form-radio-group {
    min-width: 100%;
}

.under-text {
    font-size: 15px;
    font-style: italic;
    font-weight: 300;
    line-height: 25px;
    color: #5C5C5C;
    padding-left: 13px;
}

.contactperson-sub-header {
    padding-top: 20px;
    padding-left: 9px;
    padding-bottom: 13px;
}

.font-normal {
    font-style: normal !important;
}

.register-second-header {
    padding-top: 20px;
    padding-left: 10px;
}

.p-left-15 {
    padding-left: 15px
}

.register-third-header {
    margin-bottom: 2px;
}

.p-2_5 {
    padding: 1rem !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
}

.btn-primary, .btn-secondary {
    border-color: #E10000;
    margin-top: 5px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 14px;

    &.btn-sm {
        padding: 10px
    }

    .table & {
        margin-top: 0;
    }
}

.btn-primary {
    background-color: #E10000;
    color: #ffffff;
}

.btn-secondary {
    background-color: #fff;
    color: #E10000;
}

.col-no-padding {
  padding: 0;
}

.full-width {
    width: 100%;
}

.btn-span-login {
    height: 20px;
    width: 19px;
    padding-left: 15px;
    vertical-align: inherit;
}
.register-sub-header-right {
    text-align: left;
}
.p-left-15-lg {
    padding-left: 0px;
}

.narrow {
    display: none;
}

@include media-breakpoint-up(lg) {

    .register-sub-header-right {
        text-align: right;
    }


}

@include media-breakpoint-up(xl) {
    .p-left-15-lg {
        padding-left: 15px;
    }

}

@include media-breakpoint-down(lg) {
    .narrow {
        display: block
    }

    .span-error {
        display: block;
    }

    .float-right.span-error {
        float: none !important;
    }
}

.custom-control-input:checked {
    border-color: #e10000 !important;
    background-color: #ffffff !important;
    color: #e10000 !important;
}

.checkbox-as-radio > .custom-control-label::after {
    position: absolute;
    top: -0.3rem;
    left: -1.8rem;
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    content: "";
    background: no-repeat 50% / 50% 50%;

}

.custom-control-input {
    position: relative;
}

//
// Radios that look like checkboxes
//
// Tweak just a few things for radios.
/*$custom-control-indicator-border-radius: .25rem;
$custom-control-indicator-icon-checked: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8…M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e);*/

.custom-radio.checkbox-as-radio {
    @extend .custom-checkbox;
}

.handle-error {
    label {
        color: #e10000;
    }

    .form-style {
        border-color: #e10000;
    }
}

.span-error {
    color: #e10000;
}

.span-ico-error > .icon-close {
    color: #e10000 !important;
    font-size: 22px;
}

.reset-pw-container {
    padding-top: 0px !important;

}

.main-register {
    min-height: 544px;
    padding-bottom: 100px;
}

.success-page {
    min-height: 500px;
    .btn-primary {
        padding-top: 12px;

    }
}

@include media-breakpoint-up(sm) {
    .m-sm-p-15 {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
}

@include media-breakpoint-only(sm) {
    .text-sm-font-smaller {
        font-size: .825rem;
    }
}

@include media-breakpoint-only(lg) {
    .text-lg-font-smaller {
        font-size: .825rem;
    }
}
