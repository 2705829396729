
.list-group-item.active {
  border-color: $red;
  background-color: $red;
}

.btn-square {
  height: 50px;
  width: 157px;
  padding: 13px 15px;
}

a {
  color: black;

  &.form-button-primary {
    padding-top: 14px;
  }
}

.btn-search {
  @include media-breakpoint-up(md) {
    max-width: 187px;

  }
}

.btn-danger {
  border-radius: 0;
}

.nav-link-privacy{
  color: $red;
  text-decoration: underline;
  font-weight: 300;
}

.btn:hover {
  color: white;
}

.btn-add-primary {
    height: 30px !important;
    width: 150px !important;
}

.header-btn {
  &.btn-link {
    border-bottom: 2px solid $primary;
    display: inline-block;
    font-weight: 700;
    padding-bottom: 7px;
    position: relative;
    text-decoration: none !important;
    transition: all 0.3s ease-in-out;
    vertical-align: sub;

    &.white {
      color: #fff;
      border-bottom-color: #fff;
    }
  }
}