@include media-breakpoint-up(sm) {
    .modal-dialog:not(.modal-lg) {
        max-width: 408px;
    }
}
.modal-content{
    border-radius: 0;

    .title{
        font-size: 20px;
        font-weight: bold;
        padding-top: 5px;
        padding-bottom: 15px;
    }
}

.modal-form-dialog {
    max-width: 50% !important;
}

.modal-button {
    width: 100%;
}
