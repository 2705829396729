

@include media-breakpoint-up(md) {
    @media (min-width: 1720px) {
        .register-avia {
            background-image: url('/assets/images/avia_side.png');
            background-repeat: no-repeat;
            background-position: right;
            background-size: 11.45% 28%;
        }
        .clean-logo {
            background-size: 11.45% 55% !important;
        }
    }
}


@media (min-width: 1230px) {
    @media (max-width: 1300px) {
        .register-avia {
            background-image: url('/assets/images/avia_side.png');
            background-repeat: no-repeat;
            background-position: right;
            background-size: 11.45% 22.5%;
        }
        .clean-logo {
            background-size: 11.45% 45% !important;
        }
    }
}
@media( min-width: 910px) {
    @media (max-width: 992px) {
        .register-avia {
            background-image: url('/assets/images/avia_side.png');
            background-repeat: no-repeat;
            background-position: right;
            background-size: 11.45% 18.5%;
        }
        .clean-logo {
            background-size: 11.45% 35% !important;
        }
    }
}

@media (max-width: 767px) {
    @media (min-width: 680px) {
        .register-avia {
            background-image: url('/assets/images/avia_side.png');
            background-repeat: no-repeat;
            background-position: right;
            background-size: 11.45% 15%;
        }
        .clean-logo {
            background-size: 11.45% 30% !important;
            
        }
    }
}


