.accordion {
  .card {
    margin-bottom: 20px;
    border-radius: 0;
    box-shadow: 0 2px 7px 0 rgba(183, 183, 183, 0.5);
    .collapse.show{
      .card-header {
      background-color: red;
      }
    }
    .card-header {
      height: 65px;
      background-color: white;
      margin: 1px;
      border-bottom: 0;

      padding-right: 25px;
      padding-left: 25px;

      [data-toggle="collapse"] {
        .icon-chevron-down, .icon-chevron-up {
          color: $primary;
          display: block;
        }

        .icon-chevron-down{
          font-size: 10px;
        }

        .icon-chevron-up{
          font-size: 9px;
        }

        &.collapsed {
          .icon-chevron-up {
            display: none;
          }
        }

        &:not(.collapsed) {
          color: $primary;

          .icon-chevron-down {
            display: none;
          }
        }
      }

      a{
        text-decoration: none;
        color: $dark-gray;
        font-size: 18px;
        padding: 0 0 10px 0;
      }
    }

    .card-body{

      border-bottom: 1px solid #E5E5E5;
      padding: 26px;
    }



  }
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 1px solid #E5E5E5;
}

.accordion > .card:first-of-type {
  border-bottom: 1px solid #E5E5E5;
}